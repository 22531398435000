@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .event-form{
    width: 90%;
    margin: auto;
    padding-bottom: 8%;
    /* text-align: center; */
  }
  .eventModal{
    font-size: 25px;
    font-size: 25px;
    /* background-color: rgb(232, 62, 140); */
    border: 1px;
    border-radius: 4px;
    color: black;
    /* width: 40px; */
    /* margin-top: 35px; */
    font-family: "Poppins", serif;
    text-align: left;
    margin-bottom: 1%;
    text-align: center;
    margin-top: 5%;

  }
  .App-link {
    color: #61dafb;
  }
  .eventModal-p {
    text-align: center;
  }
  .eventModal-p a{

    font-family: "Poppins", serif;
    margin-top: 5%;
    color: #282c34;;
  }
  .eventModal-p p{
    text-align: center;
    font-family: "Poppins", serif;
    margin-top: 2%;
    font-size: 10px;
    color:red
  }
  .eventEnter-body{
 
    margin: 2rem 0px 0px;
    background-color: rgb(255, 255, 255);
    padding: 10%;
    padding-left: 3%;
    padding-right: 3%;
     padding-top: 3%;
     padding-bottom: 3%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
    width: 100%;
    margin: auto;
    margin-top: 5%;
}
.event-search-code{
  margin: 2rem 0px 0px;
  background-color: rgb(255, 255, 255);
  padding: 10%;
  padding-left: 2%;
  padding-right: 3%;
   padding-top: 1%;
   padding-bottom: 4%;
  border-radius: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px; */
  width: 100%;
  margin: auto;
  margin-top: 2%;
}
.event-search-code i{
  font-size:22px;
 float: left;
 position: relative;
 bottom: 30px;
 left: 1%;
 /* bottom: 0px; */
 color: rgb(255, 51, 85);
}
.event-search-list{
  margin: 2rem 0px 0px;
  background-color: rgb(255, 255, 255);
  padding: 10%;
  padding-left: 2%;
  padding-right: 3%;
   padding-top: 1%;
   padding-bottom: 4%;
  border-radius: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px; */
  width: 100%;
  margin: auto;
  margin-top: 2%;
 
}
.event-addLink {
  /* border-bottom: solid 0.5px #000; */
  /* border-bottom: solid 1.5px rgb(242, 242, 242); */
  padding-top: 2%;
  padding-bottom: 2%;
}
.event-addLink  h2{
  -webkit-box-align: center;
  align-items: center;
  color: #000;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.25;
  font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: 3%;
}
.event-clostButton {
  /* background-color: red; */
  text-align: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 2%;
}
.event-clostButton button{
  /* margin: auto;  */
  /* background-color: red; */
  text-align: center;
  align-items: center;
}
.event-search-list i{
  font-size:22px;
 float: right;
 position: relative;
 bottom: 40px;
 left: 5%;
 /* bottom: 0px; */
 color: rgb(255, 51, 85);
}
  .event-choose-body{
     width:100%;
     /* background-color: red; */
     height: 300px;
  }
  .event-array{
    font-size: 25px;
  }
  .event-array-pro{
    font-size: 25px;
    /* color: red; */
    position: relative;
    top:35px
  }
  .eventCalender{
    width: 55%;
    /* background-color: red; */
    position: relative;
    left: 2%;
  }
  .event-back-choose{
    text-align: left;
    color: #000;
    float: left;
    position: relative;
    left: 8%;
  }
  .event-choose{
    display: flex;
    transition: all 0.15s ease-in-out 0s;
    width: 100%;
    /* height: 170px; */
    padding: 0px;
  
    /* flex-direction: column; */
    border: none;
    margin: 0.5rem;
    animation-duration: 0.1s;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-fill-mode: both; 
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
    color: inherit;
    margin: auto;
    margin-top:5%;
    /* padding-bottom: 5%; */
  }

  .event-choose-form{
    width: 90%;
    margin: auto;
    position: relative;
    /* top:30%; */
    height: 300px;
    /* background-color: red; */
    padding-top: 4%;
    /* padding-bottom: 8%; */
    /* text-align: center; */
  }
  .event-choose-left{
    width: 30%;
    margin-right: 4%;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color:rgb(0, 65, 159);
  }
  .event-choose-left-student{
    width: 30%;
    margin-right: 4%;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color:rgb(255, 166, 2);
  }
  .event-choose-right p{
    text-align: left;
    font-family: "Poppins", serif;
    padding-top: 10%;
  }
  .event-choose-left-student i{
  font-size:50px;
  color: #fff;
  }
  .event-choose-left i{
    font-size:50px;
    color: #fff;
    }
  .event-choose-right{
    width: 60%;
    /* margin-right: 4%; */
  }
  .event-login-card{
    width: 80%;
    margin: auto;
    padding-top: 10%;
    /* padding-bottom: 5%; */
  }
  .event-login-body{
    margin-bottom: 5%;
    width: 95%;
    text-align: center;
  }
  .event-login-body b{
    text-align: center;
    /* background-color: red; */
    position: relative;
    top:20px;
    font-size: 20px;
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  