
.fhANkk {
    position: fixed;
    border-radius: 50%;
    background-color: rgb(0, 0, 0);
    opacity: 0.1;
    min-width: 50vmin;
    min-height: 50vmin;
    bottom: -15vmin;
    right: -15vmin;
}


.code-error h2 i{
    margin-right: 10px;
}
.code-error h2{
    margin-left: 3%;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;

}


.code-search-list i{
    font-size:22px;
   float: right;
   position: relative;
   left: 5%;
   /* bottom: 0px; */
   color: rgb(255, 51, 85);
}
.code-search-list h3{
   
    /* margin-left: 3%; */
    -webkit-box-align: center;
    align-items: center;
    color: rgb(56, 18, 114);
    font-size: 15px;
    font-weight: 900;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-top: 2%;
}



/* web view */
@media (min-width: 1024px)  {

    .code-full{
        width: 100%;
        min-height: 100vh; 
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        background: rgb(56, 18, 114);
    }
    .coKalA {
        /* position: fixed; */
        background-color: rgb(0, 0, 0);
        transform: rotate(45deg);
        opacity: 0.1;
        top: -15vmin;
        left: -15vmin;
        min-width: 45vmin;
        min-height: 45vmin;
        
    }
    .code-image{
        width: 30%;
        margin: auto;
        text-align: center;
        margin-top: 25%;
        /* background-color: red; */
    }
    .code-body{
 
        margin: 2rem 0px 0px;
        background-color: rgb(255, 255, 255);
        padding: 10%;
        padding-left: 3%;
        padding-right: 3%;
         padding-top: 3%;
         padding-bottom: 3%;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
        width: 55%;
        margin: auto;
        margin-top: 5%;
    }
    
.code-buttom{
    font-size: 3rem;
    font-weight: bold;
    /* font-weight: 370; */
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: start;
    color: #fff;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
    padding-top: 3px;
    width: 100%;
    min-width: 100%;
    min-height: 55px;
    text-align: center;
    transition: 220ms all ease-in-out;
    position: relative;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    font-size: 17px;
    font-weight: bold;
    background: #000;
}
.code-search-list{
    margin: 2rem 0px 0px;
    background-color: rgb(255, 255, 255);
    padding: 10%;
    padding-left: 2%;
    padding-right: 3%;
     padding-top: 1%;
     padding-bottom: 4%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
    width: 55%;
    margin: auto;
    margin-top: 2%;
   
}
.code-error{
    width: 100%;
    height: 80px;
    background: rgb(255, 51, 85);
  margin-top: 8%;
  padding-top: 1.5%;
    /* border: 0.125rem solid rgb(255, 51, 85); */
}
.code-addLink {
    /* border-bottom: solid 0.5px #000; */
    border-bottom: solid 1.5px rgb(242, 242, 242);
    padding-top: 2%;
    padding-bottom: 4%;
}
.code-addLink h2{
    -webkit-box-align: center;
    align-items: center;
    color: #000;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-top: 0.5%;

}
.code-search-list a{
    text-decoration: none;
}
.code-assignment-hd  h2{ 
    -webkit-box-align: center;
    align-items: center;
    color: #000;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    position: relative;
    top:10px;
}
.code-assignment h2{
    -webkit-box-align: center;
    align-items: center;
    color: rgb(56, 18, 114);
    font-size: 15px;
    font-weight: 900;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-top: 3%;
    position: relative;
    top: 5px
}

}

/* moblie view */
@media screen and (max-width: 1023px) {

    .code-full{
        width: 100%;
        min-height: 100vh; 
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        background: rgb(56, 18, 114);
    }
    .coKalA {
        /* position: fixed; */
        background-color: rgb(0, 0, 0);
        transform: rotate(45deg);
        position: absolute;
        opacity: 0.1;
        top: -1vmin;
        left: -15vmin;
        width: 100%;
        min-height:30vmin;
        
    }
    .code-image{
        width: 90%;
        margin: auto;
        text-align: center;
        margin-top: 25%;
        z-index:100;
    }
    
    .code-body{
 
        margin: 2rem 0px 0px;
        background-color: rgb(255, 255, 255);
        padding: 10%;
        padding-left: 3%;
        padding-right: 3%;
         padding-top: 3%;
         padding-bottom: 3%;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
        width: 90%;
        margin: auto;
        margin-top: 5%;
    }
    .code-search-list{
        margin: 2rem 0px 0px;
        background-color: rgb(255, 255, 255);
        padding: 10%;
        padding-left: 2%;
        padding-right: 3%;
         padding-top: 1%;
         padding-bottom: 4%;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
        width: 90%;
        margin: auto;
        margin-top: 2%;
       
    }
    
.code-buttom{
    font-size: 3rem;
    font-weight: bold;
    /* font-weight: 370; */
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: start;
    color: #fff;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
    padding-top: 3px;
    width: 100%;
    min-width: 100%;
    min-height: 55px;
    text-align: center;
    transition: 220ms all ease-in-out;
    position: relative;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    font-size: 17px;
    font-weight: bold;
    background: #000;
}
.code-error{
    width: 100%;
    height: auto;
    background: rgb(255, 51, 85);
  margin-top: 8%;
  padding-top: 1.5%;
  padding-bottom: 1%;
    /* border: 0.125rem solid rgb(255, 51, 85); */
}
.code-addLink {
    /* border-bottom: solid 0.5px #000; */
    border-bottom: solid 1.5px rgb(242, 242, 242);
    padding-top: 2%;
    padding-bottom: 4%;
}
.code-addLink h2{
    -webkit-box-align: center;
    align-items: center;
    color: #000;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-top: 0.5%;

}
}