@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap");
.marketPalace-full{

}
.marketPalace-header{
        position: relative;
        height: 308px;
        width: 100%;
        overflow: hidden;
        background: rgb(25, 1, 75);
       padding-top: 5%;
       margin-bottom: 1%;
}
.marketPalace-header h1{
    margin-left: 4%;
    margin-bottom: 1%;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    font-weight: 800;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    text-align: center;
  
}
.marketPalace-header p{
    margin-left: 3%;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    text-align: center;
    margin-top: 0.5%;
}
.marketPalace-header-sub {
    background-color: rgb(134, 76, 191);
    color: rgb(255, 255, 255);
    min-height: 5rem;
    padding: 0.5rem 0px;
    margin: 0px 0px 0.25rem;
    margin-top: 5%;
    padding-left: 5%;
}
.Type-subject h2{
    margin-left: 3%;
    -webkit-box-align: center;
    align-items: center;
    color: #000;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}
.marketPalace-header-sub-list{
    width: 80%;
    margin: auto;
    padding-top: 2%;
}
.marketPalace-header-sub-list img{
    /* flex: 0 0 auto; */
    width: 2.1875rem;
    height: 30px;
    position: relative;
    bottom: 6px;
}
.marketPalace-header-sub-list h3{
    /* margin-left: 3%; */
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.25;
    float: right;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    position: relative;
    right: 10%
}

.show-random1{
    background:hsl(0, 0%, 96%);
}
.show-random2{
    background:hsl(0, 22%, 36%);
    color: #fff;
    /* width: 40% !important; */
}
.show-random3{
    background:hsl(171, 100%, 41%);
    color: #fff;
    /* width: 40% !important; */
}
.show-random4{
    background:#be185d;
    color: #fff;
    /* width: 40% !important; */
}
.show-random5{
    background:#404040;
    color: #fff;
    /* width: 40% !important; */
}

.show-random6{
    background:#881337;
    color: #fff;
    /* width: 40% !important; */
}
.show-random7{
    background:#be185d;
    color: #fff;
    /* width: 40% !important; */
}
.show-random8{
    background:#c026d3;
    color: #fff;
    /* width: 40% !important; */
}
.show-random9{
    background:#312e81;
    color: #fff;
    /* width: 40% !important; */
}
.show-random10{
    background:#0284c7;
    color: #fff;
    /* width: 40% !important; */
}
.show-random11{
    background:#000e21;
    color: #fff;
    /* width: 40% !important; */
}
.show-random12{
    background:#134e4a;
    color: #fff;
    /* width: 40% !important; */
}
.show-random13{
    background:#4d7c0f;
    color: #fff;
    /* width: 40% !important; */
}

.show-random14{
    background:#a16207;
    color: #fff;
    /* width: 40% !important; */
}

.show-random15{
    background:#78350f;
    color: #fff;
    /* width: 40% !important; */
}

.show-random16{
    background:#134e4a;
    color: #fff;
    /* width: 40% !important; */
}


.marketPalace-top{
    height: 300px;
    width: '20%';
    /* background: ; */
}
.variable-width{
    width: 100%;
}
.slider-container {
    max-width: 100vw;
    width: 100%;
    overflow: hidden;
}
.Type-subject h2{
    webkit-box-align: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-bottom: 0.7%;
}
.trending-body{
    margin-bottom: 5%;
    margin-left: 2%;
}
.trending-body h2{
    -webkit-box-align: center;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-bottom: 1%;
  
    margin-top: 1%;
}
/* .Type-subject{
    margin-left: 3%;
} */

:root {
  --up: 3;
  --card-margin: 12px;
  --card-shadow: rgba(46, 49, 52, 0.4);
  --color-white: #fff;
  --color-lightgrey: #ccc;
  --color-ui: #999;
  --color-ui-hover: #666;
  --cardWidth: calc(96vw / var(--up));
  --carouselWidth: calc(var(--cardWidth) * var(--num));
  --carouselLeftMargin: calc(2vw - var(--cardWidth));
}
@media screen and (max-width: 900px) {
  :root {
    /*basically mobile*/
    --up: 1;
  }
}

.carouselwrapper {
  overflow: hidden;
  background: var(--color-lightgrey);
  position: relative;
}

.carousel {
  width: var(--carouselWidth);
  margin: 0 0 0 var(--carouselLeftMargin);
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.carousel.prev {
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  -webkit-animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
          animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  -webkit-animation-name: prev;
          animation-name: prev;
}
.carousel.next {
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  -webkit-animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
          animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  -webkit-animation-name: next;
          animation-name: next;
}
.carousel li {
  padding: 10px;
  text-align: center;
  width: calc(var(--cardWidth) - var(--card-margin) * 20);
  box-sizing: border-box;
}

.ui {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: calc(100% - var(--card-margin) * 2);
  justify-content: space-between;
  padding: var(--card-margin);
  z-index: 10;
}
.ui button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-ui);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--color-white);
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
  border: 0;
  transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.ui button:hover {
  background: var(--color-ui-hover);
}
.ui button:focus {
  outline: none;
  border: 1px solid white;
}

.card {
  box-shadow: 0px 1px 2px 0px var(--card-shadow);
  border-radius: 4px;
  margin: var(--card-margin);
  /* background: var(--color-white); */
  /* width: 30%; */
}
.card .material-icons {
  font-size: 4.8rem;
}
.card p {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1rem;
  margin-bottom: 0;
}

@-webkit-keyframes next {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(0px + var(--cardWidth)));
  }
}

@keyframes next {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(0px + var(--cardWidth)));
  }
}
@-webkit-keyframes prev {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(0px - var(--cardWidth)));
  }
}
@keyframes prev {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(0px - var(--cardWidth)));
  }
}

.jRpFnT {
    width: 200px;
    height: 275px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
}
.iaUqVE {
    max-width: 200px;
    height: 133px;
    position: relative;
}
.jDhsfY {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    max-height: inherit;
    border-radius: 0.25rem 0.25rem 0px 0px;
}

.khjBgt {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    padding: 0.5rem;
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 0px 0px 0.25rem 0.25rem;
    height: 162px;
    box-sizing: border-box;
}

.dBBAYx {
    min-height: 1.25rem;
    margin-bottom: 0.25rem;
}
.clrUfu {
    height: 1.5rem;
    padding-left: 2.5%;
    padding-right: 2.5%;
    margin: 0px 4px 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    border: 1px solid rgb(242, 242, 242);
    border-radius: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    width: fit-content;
    cursor: default;
}
.hGYEEJ {
    height: 1.5rem;
    padding: 0px 8px;
    margin: 0px 4px 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    border: 1px solid rgb(242, 242, 242);
    border-radius: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    cursor: default;
}
.bTxeJG {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: rgb(51, 51, 51);
    width: 100%;
    height: calc(2.5rem);
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.gvGjEP {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 0.6875rem;
    line-height: 0.875rem;
    font-weight: 500;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0.5rem;
}
.gswzWc {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: end;
    justify-content: end;
}
.marketPalce-modal{
  width: 90%;
  z-index: 200 !important;
  
  /* height: 400px; */
}
.marketPlace-imageBook{
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: calc(125%);
  border-radius: 0.625rem;
  background: url(https://images-cdn.kahoot.it/c6a7c66b-2e5e-47a6-90d0-118a01a468d1?auto=webp&width=1000) center center / cover 
}



.marketPlace-ageDel{
  display: flex;
  text-align: center;
  border-top: 1px solid #D5BEEE;;
  border-bottom: 1px solid #D5BEEE;;
}
.marketPlace-titleModal h2{
 margin-top: 10%;
 margin-bottom: 2%;
 -webkit-box-align: center;
 align-items: center;
 color: rgb(51, 51, 51);
 font-size: 19px;
 font-weight: 600;
 line-height: 1.25;
 font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
 word-break: break-word;
 overflow-wrap: break-word;
}
.marketPlace-profile{
  width: 40%;
  /* background-color: red; */
  margin-bottom: 3%;
  margin-top: 5%;
}
.marketPlace-profile-left{
  float: left;
}
.profile-mage {
  background: url(https://images-cdn.kahoot.it/3eb57676-7306-46c0-981f-0f00ddd8289a?auto=webp&width=32) 100% center / cover no-repeat rgb(242, 242, 242);
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  flex-shrink: 0;
  margin: 4px 8px;
  display: inline-block;
}
.marketPlace-profile-right{
  /* float: right; */
  /* background-color: blue; */
  position: relative;
  width: 100%;
  /* right:  15%; */
}
.marketPlace-profile-right h2{

   -webkit-box-align: left;
   align-items: left;
   color: rgb(51, 51, 51);
   font-size: 14px;
   font-weight: 600;
   line-height: 1.25;
   font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
   word-break: break-word;
   overflow-wrap: break-word;
}
.marketPlace-profile-right h4{

  -webkit-box-align: left;
  align-items: left;
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-break: break-word;
  overflow-wrap: break-word;
}
.marketPlace-download{
  margin-top: 3%;
  margin-bottom: 2%;
  margin-left: 4%;
}
.marketPlace-ageDel{
  margin-top: 4%;
  margin-bottom: 2%;
}
.marketPlace-ageDel div{
  border-right: 1px blanchedalmond solid;
  border-left: 1px blanchedalmond solid;
  width: 23%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.marketPlace-ageDel div h2{
  -webkit-box-align: left;
  align-items: left;
  color: rgb(51, 51, 51);
  font-size: 15px;
  font-weight: 600;
  line-height: 1.25;
  font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: 5%;
  margin-bottom: 1%;
}
.marketPlace-desc{
  margin-top: 2%;
}
.marketPlace-desc h2{
  -webkit-box-align: left;
  align-items: left;
  color: rgb(51, 51, 51);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25;
  font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: 5%;
  margin-bottom: 1%;
}
.marketPlace-desc p{
  -webkit-box-align: left;
  align-items: left;
  color: rgb(51, 51, 51);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.25;
  font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-break: break-word;
  overflow-wrap: break-word;
}