*{
    margin: 0;
    padding: 0;
}
.start{
    text-align: center;
    align-items: center;
}
.start h1{
    font-size: 70px;
    font-weight: bolder;
    color: black;
    text-align: center;
    align-items: center;
    /* margin-left: 500px; */
    margin-top: 25px;
    font-family: "Poppins", serif;
}

.start p{
    font-size: 18px;
    /* margin-left: 650px; */
    margin-top: 15px;
    font-family: "Poppins", serif;
    text-align: center;
    align-items: center;
}
.reg-flex-caro{
    width: 80%;
    margin: auto;
    /* background-color: red; */
}
.allow-flex{
    /* background-color: red; */
    display: flex;
    text-align: center;
    align-items: center;
}

.beauty{
    height: 510px;
    width: 42%;
    float: left;
    position: relative;
    left: 18%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
    /* margin-left: -475px; */
    margin-top: 128px; 
    overflow: hidden;
}

.briana{
    width: 39%;
    height: 510px;
    align-items: center;
    margin: auto;
    /* margin-left: 650px; */
    overflow: hidden;
    z-index: 0;
    position: relative;
    margin-top: 12%;
    right: 15%;
   }

.res-text{
    width: 55%;
    /* background-color: green; */
    position: relative;
    margin-top: 10%;
    right: 4%
}
.res-text h1{
    font-family: "Poppins", serif;
    /* margin-top: 5%; */
    margin-left: 1%;
    color: black;
    font-size: 25px;
}

.res-text h2{
    font-family: "Poppins", serif;
    margin-top: 20px;
    margin-left: 1%;
    color: black;
    font-size: 15px;
}

.res-text p{
    font-family: "Poppins", serif;
    margin-top: 4px;
    /* margin-left: 210px; */
    font-size: 15px;
}

.reg-how{
    font-size: 30px;
    font-weight: bolder;
    color: black;
    /* background-color: red; */
    padding-bottom: 2.5%;
    /* margin-left: 400px; */
   
    font-family: "Poppins", serif;
    text-align: center;
    width: 89%;
    margin: auto;
    margin-top: 75px;
}
.reg-how h1{
    font-size: 150%;
    font-family: "Poppins", serif;
    font-weight: 12%;
}

.res-triangle{
    display: flex;
}
.triangle{
    background-color: white;
    height: 555px;
    width: 38%;
    margin-left: 5px;
    margin-top: 35px;
    border: 1px solid black;
    border-radius: 5px;
}


.triangle h1{
    font-size: 25px;
    background-color: aquamarine;
    border: 1px;
    border-radius: 4px;
    width: 40px;
    margin-top: 35px;
    font-family: "Poppins", serif;
    text-align: center;
    margin-left: 30px;
}

.triangle h2{
    font-size: 45px;
    text-align: left;
    margin-top: 10px;
    font-family: "Poppins", serif;
    margin-left: 30px;
    color: black;
}

.triangle p{
    font-size: 14px;
    margin-top: 10px;
    font-family: "Poppins", serif;
    margin-left: 30px;
    text-align: left;
}

.rectangle{
    background-color: white;
    height: 115px;
    width: 80%;
    margin-left: 30px;
    margin-top: 35px;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;
    /* display: flex; */
    /* z-index: 4;/ */
    position: relative;
}

.english{
    height: 150px;
    height: 80%;
    margin-top: 10px;
    /* margin-left: 10px; */
    width: 30%;
    background-color: green;
    float: left;
    
}
.rectangle-sub {
    width: 69%;
    /* background-color: red; */
    text-align: left;
    float: right;
 }
 .flex {
   padding-top: 0.5%;
    display: flex;
}
.res-name{
    width: 79% !important;
    /* background-color: yellow; */
    /* text-align: left; */
    /* float: left; */
}
.reg-font{
    /* float: right; */
    width: 25% !important;
    display: flex;
    /* text-align: left; */
   /* background-color: green; */
   padding-top: 5px;
}
.reg-font i{
    font-size: 20px;
    /* background-color: red; */
    margin-left: 0% !important;
   
    /* float: left; */
}
.reg-font p{
    width: 100%;
    margin-left: 0% !important;
    font-size: 18px;
    position: relative;
    bottom:10px
    /* background-color: red; */
    /* text-align: left; */
    /* font-size: 20px; */
}

.flex h3{
    font-size: 20px;
    /* margin-top: -110px; */
    font-family: "Poppins", serif;
    /* margin-left: 120px; */
    color: black;
}



.cap{
    /* margin-left: 120px; */
    margin-top: -18px; 
}

.fa-graduation-cap{
    font-size: 15px;
}

.cap p{
    font-size: 14px;
    margin-top: -28px;
    margin-left: 25px ;
}

.language{
    /* margin-left: 120px; */
    margin-top: -18px;
}

.fa-language{
    font-size: 15px;
}

.language p{
    font-size: 14px;
    margin-top: -28px;
    margin-left: 25px ;
}

.rectangle-1{
    background-color: white;
    height: 115px;
    width: 80%;
    margin-left: 55px;
    margin-top: -30px;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;
   
}

.english-man{
    height: 150px;
    height: 80%;
    margin-top: 10px;
    margin-left: 10px;
}

.cap-1{
    margin-left: 120px;
    margin-top: -100px; 
}


.fa-graduation-cap{
    font-size: 15px; 
}

.cap-1 p{
    font-size: 14px;
    margin-top: -28px;
    margin-left: 25px ;
}


.language-1{
    margin-left: 120px;
    margin-top: -18px;
}

.fa-language{
    font-size: 15px;
}

.language-1 p{
    font-size: 14px;
    margin-top: -28px;
    margin-left: 25px ;
}

.rectangle-2{
    background-color: white;
    height: 115px;
    width: 80%;
    margin-left: 78px;
    margin-top: 6px;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;
    z-index: 4;
}

.english-woman{
    height: 150px;
    height: 80%;
    margin-top: 10px;
    margin-left: 10px;
}

.cap-2{
    margin-left: 190px;
    margin-top: -105px; 
}

.fa-graduation-cap{
    font-size: 15px;
}

.cap-2 p{
    font-size: 14px;
    margin-top: -28px;
    margin-left: 25px ;
}

.language-2{
    margin-left: 190px;
    margin-top: -18px;
}

.fa-language{
    font-size: 15px;
}

.language-2 p{
    font-size: 14px;
    margin-top: -28px;
    margin-left: 25px ;
}


.triangle-1{
    background-color: white;
    height: 700px;
    width: 29%;
    margin-left: 50px;
    margin-top: 35px;
    border: 1px solid black;
    border-radius: 5px;
}


.triangle2 {
    overflow:hidden;
    /* background-color: green; */
    height: 29.2ch;
}
.triangle2 h1{
    font-size: 25px;
    background-color: #f5c52d;
    border: 1px;
    border-radius: 4px;
    width: 40px;
    margin-top: 35px;
    font-family: "Poppins", serif;
    text-align: center;
    margin-left: 30px;
}

.triangle2 h2{
    text-align: left;
}

.triangle2 p{
    margin-bottom: 10%;
    text-align: left;
}

.triangle2 img{
    width: 95%;
    /* overflow: hidden; */
    /* height: 300px; */
}
.triangle3 h1{
    font-size: 25px;
    background-color: rgb(232, 62, 140);
    border: 1px;
    border-radius: 4px;
    width: 40px;
    margin-top: 35px;
    font-family: "Poppins", serif;
    text-align: center;
    margin-left: 30px;
    margin-bottom: 5%;

}

.triangle3 h2{
    text-align: left;
    line-height: 90%;
    margin-bottom: 5%;
}

.triangle3 p{
    text-align: left;
    margin-bottom: 10%;
    
}

.triangle3 img{
    width: 95%;
    /* overflow: hidden; */
    /* height: 300px; */
}

.reg-lession{
    width: 100%;
    background-color: rgb(232, 62, 140);
    padding-top:7%;
    padding-bottom: 7%;
}
.reg-lession h1{
    font-size: 70px;
    font-size: 80px;
    /* background-color: rgb(232, 62, 140); */
    border: 1px;
    border-radius: 4px;
    color: black;
    /* width: 40px; */
    /* margin-top: 35px; */
    font-family: "Poppins", serif;
    text-align: center;
    margin-left: 30px;
    /* margin-bottom: 5%;  */
}

.reg-lession h4{
    font-size: 25px;
    color: black;
    text-align: center;
    margin-top: 15px;
    font-family: "Poppins", serif;
    /* margin-top: -28px;
    margin-left: 25px ; */
}
.regstart-first h2{
    font-size: 30px;
    /* background-color: rgb(232, 62, 140); */
    border: 1px;
    border-radius: 4px;
    color: black;
    /* width: 40px; */
    /* margin-top: 35px; */
    font-family: "Poppins", serif;
    text-align: center;
    margin-bottom: 6%;
    margin-top: 5%;
    /* margin-left: 30px; */
}
.reg-lession-sub{
    text-align: center;
    align-items: center;
    padding-top: 3%;
}
.paystack-body h1{
    font-size: 30px;
    /* font-size: 25px; */
    /* background-color: rgb(232, 62, 140); */
    border: 1px;
    border-radius: 4px;
    color: black;
    /* width: 40px; */
    /* margin-top: 35px; */
    font-family: "Poppins", serif;
    text-align: center;
    margin-bottom: 3%;
    text-transform: capitalize;
}
.regstart-body{
    width:100%;
    display: flex;
}
.regstart-slide-imge{
    width:38%;
    background-color: white;
    height:87vh;
    margin-left: 30px;
    margin-top: 2px;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;
    /* display: flex; */
    /* z-index: 4;/ */
    position: relative;
}
.regstart-slide-form{
    width:62%;
    /* background-color: red; */
}
.slide-imge-rig{
    /* background-image: url("https://img.freepik.com/premium-photo/abstract-wallpapers-coloring-shapes-waves_853115-42.jpg?size=626&ext=jpg&ga=GA1.2.25492661.1706219685&semt=ais"); */
}
.rig-paymet-body{
    width: 70%;
    border-radius: 5px;
    border: 1px solid black;
    padding-top: 3%;
    padding-bottom: 5%;
    text-align: center;
    text-align: center;
    display: flex;
    margin: auto;
}
.rig-paymet-sub1{
    width: 70%;
    text-align: left;
    align-items: left;
    padding-left: 3%;
 
    /* background-color: black; */
}
.rig-paymet-sub1 h4{

    font-size: 15px;
    /* background-color: rgb(232, 62, 140); */
    border: 1px;
    border-radius: 4px;
    color: red;
    /* width: 40px; */
    /* margin-top: 35px; */
    font-family: "Poppins", serif;
    text-align: left;
    margin-bottom: 4%;

}

.rig-paymet-sub1 h2{
    font-size: 25px;
    font-size: 25px;
    /* background-color: rgb(232, 62, 140); */
    border: 1px;
    border-radius: 4px;
    color: black;
    /* width: 40px; */
    /* margin-top: 35px; */
    font-family: "Poppins", serif;
    text-align: left;
    margin-bottom: 1%;
    /* margin-left: 30px; */

}
.rig-paymet-sub1 p{
    font-size: 15px;
    /* background-color: rgb(232, 62, 140); */
    border: 1px;
    border-radius: 4px;
    color: black;
    /* width: 40px; */
    /* margin-top: 35px; */
    font-family: "Poppins", serif;
    text-align: left;
    margin-bottom: 2%;
    /* margin-left: 30px; */
}
.rig-paymet-sub1 a{
    margin-left: 5%;
}
.rig-paymet-sub2{
    text-align: center;
    align-items: center;
    /* background-color: red; */
    padding-left: 8%;
}
.rig-paymet-sub2 img{
    margin-top: 25%;
    
}
.rig-paymet-sub2{
    width: 20%;
    /* background-color: red; */
}

.rig-paymet-sub1 button {
    cursor: pointer;
    text-align: center;
    font-size: 17px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    background-color: red;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 40%;
    height: 45px;
    margin-top: 40px 
  }