


/* web view */
@media (min-width: 1024px)  {
    .signupPhone{
        transition: all 0.15s ease-in-out 0s;
        width: 35%;
        height: auto;
        border: none;
        /* margin: 0.5rem; */
        animation-duration: 0.1s;
        animation-delay: 0s;
        animation-timing-function: ease-in;
        animation-fill-mode: both; 
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
        color: inherit;
        margin: auto;
        margin-top:2%;
        padding-top: 3%;
        text-align: left;
    }
    .loginBirthBody{
        transition: all 0.15s ease-in-out 0s;
        width: 35%;
        height: 170px;
        padding: 0px;
      
        /* flex-direction: column; */
        border: none;
        margin: 0.5rem;
        animation-duration: 0.1s;
        animation-delay: 0s;
        animation-timing-function: ease-in;
        animation-fill-mode: both; 
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
        color: inherit;
        margin: auto;
        margin-top:5%;
        
    }
    .loginBirthBodyFome{
        display: flex;
    }
    .signUpDate{
        width:35%;
        /* background-color: red; */
    }
    .signUpDateSub{
        width: 90%;
    }
    .loginChooseFullBody{
        margin-top: 7%;
        text-align: center;

    }
    .loginChooseFullBody h2{
        font-size: 25px;
        font-family: "Poppins", serif;
        
    }
  
    .loginChooseBody{
        width: 70%;
        margin: auto;
        display: flex;
        justify-content: center;
        margin-top: 5%
    }
    .loginChoose {
        transition: all 0.15s ease-in-out 0s;
        width: 15.5rem;
        height: 280px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        border: none;
        margin: 0.5rem;
        animation-duration: 0.1s;
        animation-delay: 0s;
        animation-timing-function: ease-in;
        animation-fill-mode: both;
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
        color: inherit;
    }
    .loginChoose-subProfessional{
        width: 3%;
        height: 40px;
        float:left;
        background-color:rgb(0, 65, 159);
        position: absolute;
        border-top-right-radius: 45%;
        border-bottom-right-radius: 45%;
        float:left;
    }
    .loginChoose-subTeacher{
        width: 3%;
        height: 40px;
        float:left;
        background:rgb(171, 0, 24);        ;
        position: absolute;
        border-top-right-radius: 45%;
        border-bottom-right-radius: 45%;
        float:left;
    }
    .loginChoose-subStudent{
        width: 3%;
        height: 40px;
        float:left;
        background-color: rgb(255, 166, 2); 
        position: absolute;
        border-top-right-radius: 45%;
        border-bottom-right-radius: 45%;
        float:left;
    }
    .loginChooseHover:hover{
        background-color: rgb(0, 65, 159);;
        border-top-left-radius: 50%;
    }
    .loginChooseHoverTeacher:hover{
        background:rgb(171, 0, 24); 
        border-top-left-radius: 50%;
    }
    .loginChooseHoverStudent:hover{
        background-color: rgb(255, 166, 2);
        border-top-left-radius: 50%; 
    }
    .loginChoose-font{
            height: 100px;
            width: 100px;
            border-radius: 50%;
            padding-left: auto;
            text-align: center;
            margin: auto;
            margin-top: 20%;
    }
    .Professional{
        background-color:rgb(0, 65, 159)
    }
    .Teacher{
        background:rgb(171, 0, 24);        ;
    }
    .Student{
        background-color: rgb(255, 166, 2); 
    }
    .loginChoose-font i{
     font-size: 50px;
     margin-top: 25%;
     color: #fff;
    }
    .loginChoose-title{
      text-align: center;
      font-size: 20px;
      font-family: "Poppins", serif;
      height: 60px;
      margin-top: 20%;
      /* background-color:blue; */
      width: 100%;
      padding-top: 5%;
      color: #fff;
    }
    .loginChoose{

    }
    .login-div{
        width: 26%;
        margin: auto;
        margin-top: 2%;
    }
    .login-logo{
        text-align: center;
        margin-bottom: 10%;
    }
    .login-logo p{
        text-align: center;
        /* background-color: red; */
        
    }
    .login-logo p img{
        margin: auto;
    }
    .login-logo h3{
        text-align: center;
        color: rgb(51, 51, 51);
        font-size: 20px;
        font-weight: 500;
        line-height: 1.25;
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        word-break: break-word;
        overflow-wrap: break-word;
        margin-top: 5%;
    }
    .login-forget{
        text-align: center;
        color: rgb(51, 51, 51);
        font-size: 15px;
        font-weight: 500;
        line-height: 1.25;
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        word-break: break-word;
        overflow-wrap: break-word;
        margin-top: 5%;
    }
}

/* moblie view */
@media screen and (max-width: 1023px) {


    .login-div{
        width: 90%;
        margin: auto;
        margin-top: 15%;
    }
    .login-logo{
        text-align: center;
        margin-bottom: 10%;
    }
    
    .login-forget{
        text-align: center;
        color: rgb(51, 51, 51);
        font-size: 15px;
        font-weight: 500;
        line-height: 1.25;
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        word-break: break-word;
        overflow-wrap: break-word;
        margin-top: 5%;
    }
}