

/* @import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma-rtl.min.css"; */
@tailwind base;
@tailwind components;
@tailwind utilities;

.NKmQR {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  height: 3.5rem;
  padding: 0px 0.5rem;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: rgb(51, 51, 51);
  margin: 0px 1rem 0px 0px;
  cursor: pointer;
  overflow: visible;
}
.navbar-brand-bt{
  align-items: stretch;
  display: flex;
  width: 10%;
  flex-shrink: 0;
  min-height: 3.25rem;
}
.active{
  color: rgb(70, 23, 143);
}
body::-webkit-scrollbar{
  display: none;
 
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}