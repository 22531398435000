.rightPanel {
  padding: 10px;
  margin: 10px;
  /* background-color: red; */
  /* float: right; */
  /* width: 75%; */
  /* height: 90vh; */
  /* overflow: auto; */
  margin-bottom: 45px;
}
.col-form-label {
  text-align: right;
}
.fullbody{
  width: 100%;
  margin: auto;
  /* background-color: red; */
}
.subbody{
  width: 100%;
  margin: auto;
  padding-top: 2%;
  margin: auto;
  /* background-color: red; */
  /* background-color: black; */
  /* padding-left: 10%; */
}
.button-style{
  text-align: center;
  align-items:center;
  /* background: wheat; */
  margin: auto;

}
.reg-found{
  width: 80%;
  margin: auto;
  /* background-color: black; */
}
.reg-found p{
  color: var(--e-global-color-60a76ab5);
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  margin-top: 3px;
}
.row-coustom{
  padding: 0%;
  width: 100%;
  margin: auto;
  margin-left: 1%;
}
.list-found{
  margin-left: 4%;
}
.list-subject ul li{
  display: inline-block;
}
.list-subject ul li{
  background-color: rgb(255, 255, 255);
  padding: 2%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
   margin-left: 1%;
   margin-top: 1%;
}
.list-subject ul li label  {
  font-size: 1rem;
  font-weight: bold;
  /* margin-right: 20px; */
}
.body-buttom{
  width: 60%;
  /* background-color: brown; */
  margin: auto;
  text-align: center;
  text-align: center;
}
.res-error{
  /* text-align: center; */
  color: red;
  font-family: "Poppins", Sans-serif;
  position: relative;
  /* bottom: 12px; */
}
.registar-class{
  margin-top: 3%;
  margin-left: 4%;
}
.body-buttom button{
  text-align: center;
  align-items: center;
  position: relative;
  left: 40%;
  /* margin-top: 1%; */
}
.res-value {
  text-align: center;
  color: var(#000);
  font-family: "Poppins", Sans-serif;
  position: relative;
  bottom: 12px;
}
.list-found ul li label input[type=checkbox] {
  transform: scale(1.3);
  margin-right: 15px;
}
.list-subject p{
  color: var(#000);
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  margin-top: 15px;
  margin-left: 12%;
}
.list-subject ul{
  width: 90%;
  margin: auto;
  margin-left: 7%;
}
.list-subject ul li label input[type=checkbox] {
  transform: scale(1.8);
  margin-left: 15px;
}
@media only screen and (max-width: 768px) {
  .col-form-label {
    text-align: left;
  }
  .rightPanel {
    width: 100%;
    margin-bottom: 50px;
  }
}