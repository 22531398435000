

.nav-create-buttom{
  font-size: 1rem;
  font-weight: bold;
  /* font-weight: 370; */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.20em 0.60em;
  padding-top: 3px;
  min-width: 15ch;
  /* margin-left: 1%; */
  margin-right: 5%;
  min-height: 38px;
  text-align: center;
  /* line-height: 1.1; */
  transition: 220ms all ease-in-out;
  position: relative;
  top:15%;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  font-size: 15px;
  font-weight: bold;
 background: rgb(70, 23, 143);
}
.nav-class-buttom{
  font-size: 1rem;
  font-weight: bold;
  /* font-weight: 370; */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.10em 0.20em;
  padding-top: 3px;
  min-width: 10ch;
  /* margin-left: 1%; */
  margin-right: 5%;
  min-height: 38px;
  text-align: center;
  /* line-height: 1.1; */
  transition: 220ms all ease-in-out;
  position: relative;
  top:15%;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  font-size: 15px;
  font-weight: bold;
 background: rgb(70, 23, 143);
}
.nav-class-buttom:hover{
  font-size: 1rem;
  font-weight: bold;
    /* font-weight: 370; */
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: start;
    background-color: #2ba27c;
    color: #000;
    border-radius: 8px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
    padding: 0.20em 0.60em;
    padding-top: 3px;
    min-width: 11ch;
    /* margin-left: 1%; */
    margin-right: 5%;
    min-height: 38px;
    text-align: center;
    /* line-height: 1.1; */
    transition: 220ms all ease-in-out;
    position: relative;
    top:15%;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    font-size: 15px;
    font-weight: bold;
    background: #ffffff;
  
}
.nav-create-buttom:hover{
font-size: 1rem;
font-weight: bold;
  /* font-weight: 370; */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  background-color: #2ba27c;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.20em 0.60em;
  padding-top: 3px;
  min-width: 15ch;
  /* margin-left: 1%; */
  margin-right: 5%;
  min-height: 38px;
  text-align: center;
  /* line-height: 1.1; */
  transition: 220ms all ease-in-out;
  position: relative;
  top:15%;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  font-size: 15px;
  font-weight: bold;
  background: #ffffff;
}
.nav-share-button{
font-size: 1rem;
font-weight: bold;
  /* font-weight: 370; */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  background-color: #2ba27c;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.20em 0.60em;
  padding-top: 3px;
  min-width: 15ch;
  /* margin-left: 1%; */
  margin-right: 5%;
  min-height: 38px;
  text-align: center;
  /* line-height: 1.1; */
  transition: 220ms all ease-in-out;
  position: relative;
  top:15%;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  font-size: 12px;
  font-weight: bold;
  background: #ffffff; 
}
.nav-share-button:hover{
font-size: 1rem;
font-weight: bold;
  /* font-weight: 370; */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  background-color: #2ba27c;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.20em 0.60em;
  padding-top: 3px;
  min-width: 15ch;
  /* margin-left: 1%; */
  margin-right: 5%;
  min-height: 38px;
  text-align: center;
  /* line-height: 1.1; */
  transition: 220ms all ease-in-out;
  position: relative;
  top:15%;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  font-size: 12px;
  font-weight: bold;
  background-color: rgb(38, 137, 12)
}
.nav-profile-body{
width: 20%;
height: 1px;
/* background: red; */
float: right !important;
position: absolute;
top:68px;
right: 2%;
/* left:25.4% */

}
.nav-profile-sub{
background: white;
box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
border-radius: 10px;
padding: 4%;
}

.nav-profile-p{
padding-bottom: 3%;
border-bottom: rgba(0, 0, 0, 0.15) solid 2px;
margin-top: 3%;
}
.nav-profile-p p{
color:#000;
font-size: 80%;
}
.nav-profile-p-out{
padding-bottom: 3%;
/* border-top: rgba(0, 0, 0, 0.15) solid 2px; */
margin-top: 3%;
}
.nav-profile-p-out p{
color:red;
font-size: 80%;
}
.nav-profile-p-out p i{
color:red;
font-size: 150%;
position:relative;
top:4px

}
.nav-pay-quiz{
   /* outline: 0; */
font-size: 1rem;
font-weight: bold;
  /* font-weight: 370; */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  background-color: #2ba27c;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.20em 0.60em;
  padding-top: 3px;
  min-width: 15ch;
  /* margin-left: 1%; */
  margin-right: 5%;
  min-height: 38px;
  text-align: center;
  /* line-height: 1.1; */
  transition: 220ms all ease-in-out;
  position: relative;
  top:15%;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  font-size: 12px;
  font-weight: bold;
  background: #292A3A;

}
.nav-pay-quiz:hover{ 
font-size: 1rem;
font-weight: bold;
  /* font-weight: 370; */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  background-color: #2ba27c;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.20em 0.60em;
  padding-top: 3px;
  min-width: 15ch;
  /* margin-left: 1%; */
  margin-right: 5%;
  min-height: 38px;
  text-align: center;
  /* line-height: 1.1; */
  transition: 220ms all ease-in-out;
  position: relative;
  top:15%;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  font-size: 12px;
  font-weight: bold;
  background: #ffffff;
}
.nav-login{
/* outline: 0; */
font-size: 1rem;
font-weight: bold;
/* font-weight: 370; */
text-decoration: none;
display: inline-flex;
align-items: center;
justify-content: center;
align-self: start;
background-color: #2ba27c;
color: #fff;
border-radius: 8px;
box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
padding: 0.20em 0.60em;
padding-top: 3px;
min-width: 10ch;
/* margin-left: 1%; */
margin-right: 5%;
min-height: 38px;
text-align: center;
/* line-height: 1.1; */
transition: 220ms all ease-in-out;
position: relative;
top:15%;
box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
font-size: 12px;
font-weight: bold;
background: #292A3A;

}
.nav-login:hover{ 
font-size: 1rem;
font-weight: bold;
/* font-weight: 370; */
text-decoration: none;
display: inline-flex;
align-items: center;
justify-content: center;
align-self: start;
background-color: #2ba27c;
color: #000;
border-radius: 8px;
box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
padding: 0.20em 0.60em;
padding-top: 3px;
min-width: 10ch;
/* margin-left: 1%; */
margin-right: 5%;
min-height: 38px;
text-align: center;
/* line-height: 1.1; */
transition: 220ms all ease-in-out;
position: relative;
top:15%;
box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
font-size: 12px;
font-weight: bold;
background: #ffffff;
}
.nav-take-quiz{
/* outline: 0; */
font-size: 1rem;
font-weight: bold;
  /* font-weight: 370; */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  background-color: #2ba27c;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.20em 0.60em;
  padding-top: 3px;
  min-width: 10ch;
  min-height: 38px;
  text-align: center;
  /* line-height: 1.1; */
  transition: 220ms all ease-in-out;
  position: relative;
  top:15%

}
.nav-link-contom-login{
color: #000;
font-size: 130%;
}
/* web view */
@media (min-width: 1024px)  {
  .nav-logo{
    width:30%;
    height:80%
  }
  .nav-left{
    display: flex;
    width: 30%;
  }
.navbar-item-img-cos img{
  position: relative;
  left: 250%;
}

 .navbar-item-costom{
   display: none
 }
 .navbar-userbutton{
   width:120px;
   position:relative;
    top:12%;
    right: 2%;
 }
 .navbar-userbutton:first-child {
  margin-left: 30%;
}
 .navbar-userbutton i{
  font-size: 150%;
   /* margin-left: 1; */
 }
  .nav-constom{
      position: relative;
      /* background: red; */
      padding-left: 5%;
      left: 59%;
     }
     .navbar-end-costom{
      display: none
    }
    .nav-class-costom{
      /* box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15); */
      background: none;
    }
    .nav-dropdown {
      position: absolute;
      background:#fff;;
     /* margin-top: 4%; */
      width: 18%;
      display: none;
      z-index: 1;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
      /* padding-left: 3%; */
      border-radius: 10px;
      padding-top: 1.6%;
      padding-bottom: 2.6%;
    }
    .navbar-item:hover {
      color: #fff !important;
    }
    .nav-dropdown li {
      margin-top: 5px;
      padding-left: 5%;
      color: black;
    }
    .nav-dropdown li a {
      color: black;
    }
    .nav-web-display{
     background: none;
     padding-top: 1.7%;
    }
    .nav-web-display a{
      color: #fff;
    }
    .nav-web-display a:hover{
      background: none;
      background-color: #3298dc;
      width: 100%;
  }
  .nav-link-contom{
    display: none;
  }
  .nav-pay-quiz-moblie{
    display: none
}
.trans-loader-Vendors{
background: rgba(32, 32, 32, 0.1);
z-index: 2000;
position: fixed;
height: 100%;
width: 100%;
left: 0;
top: 0;
}
.trans-pre-Vendors{
background: #fff;
width: 35%;
height:auto;
margin: auto;
margin-top: 100px;
box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
border-radius: 20px;
padding:15px;
padding-bottom: 20px;
}
.trans-button-body{
width: 40%;
margin-left: 10px;
border-radius: 10%;
display: inline-table;
padding-top: 10%;
padding-bottom: 10%;
cursor: pointer;
box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}
.trans-button-body i{
 font-size: 200%;
}
.trans-div-input-body{
padding-top: 80px;
}
.nav-body-create{
width: 100%;
padding:3%;
padding-top: 8%;
padding-bottom: 5%;
text-align: center;
/* background: red ; */
margin-top: 10%;
}
.nav-body-create a{
margin-left: 5%;
margin: auto;
}
.nav-share-body{
width:90%;
border-radius: 4px;
background-color: rgb(242, 242, 242);
text-align: center;
padding: 8px 0px;

}
.nav-share-body img{
width: 6rem;
padding: 16px 0px;
margin: 0px auto;
display: block;
/* margin:auto; */
}
.nave-share-sub{
margin-top:10%;
margin-bottom:10%;
}
.trans-pre-Vendors h1{ 
  font-size: 1.5rem;
  font-weight: 700;
  float: left;
  line-height: 1.33;
}
.nav-news-sub{
  display: flex;
  border-top: 1px solid rgb(242, 242, 242);
  padding-top: 1%;
}

.nav-news-sub-img{
  width: 20%;
  padding:1%;
  position: relative;
  top:1%;
  /* background: #000; */
  padding-top: 1%;
 
}
.nav-news-sub-img img{
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 4px;
}
.nav-news-article{
    width: 95%;  
    padding-right: 0%;
    position: relative;
    text-align: left;
    right:1%
}
.nav-news-article p{
    font-size: 85%;
}
.nav-input-Search{
 margin-bottom: 5%;
}
.nav-question-sub{
  background: #fff;
  display: flex;
  /* border-top: 1px solid rgb(242, 242, 242); */
  box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
  /* border-bottom: 1px solid rgb(242, 242, 242); */
  padding-top: 1%;
  padding-bottom: 1%;
  margin-top: 2%;
  position: relative;
  
}

.nav-question-sub-img{
  width: 30%;
  padding:1%;
  position: relative;
  top:1%;
  /* background: #000; */
  padding-top: 1%;
  min-height: 74px;
  min-width: 109px;
  background: url(https://images-cdn.kahoot.it/09a90579-a986-4ac4-8b9c-dd3045cda763?auto=webp&width=300) 50% center / cover rgb(204, 204, 204);
  border-radius: 4px 0px 0px 4px;
 
}
.nav-question-sub-title {
  display: inline-block;
  position: absolute;
  bottom: 8px;
  left: 8px;
  background-color: rgba(51, 51, 51, 0.8);
  padding: 1.5px 8px;
  border-radius: 4px;
}
.nav-question-sub-title span{
  margin: 0px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: bold;
}
.nav-question-data{
    width: 90%;  
    padding-right: 0%;
    position: relative;
    text-align: left;
    left: 1%;
    /* right:1% */
}
.nav-question-data p{
    font-size: 85%;
}
.nav-question-total {
  width: 100%;
  min-height: 22px;
  height: 24px;
  background-color: rgb(242, 242, 242);
  padding-top: 2%;
  padding-bottom: 10%;
  position: relative;
  top:5%;
  right: 1%;
  
} 
.nav-question-total-span1{
  float: right;
  position: relative;
  right: 4%;
}
.nav-question-total-span2{
  /* float: right; */
  position: relative;
  left: 4%;
}
.nav-choose-which-body{
  /* background: #fff; */
  display: flex;
  /* border-top: 1px solid rgb(242, 242, 242); */
  /* box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17); */
  /* border-bottom: 1px solid rgb(242, 242, 242); */
  padding-top: 1%;
  padding-bottom: 1%;
  margin-top: 2%;
  position: relative;
  
}
.nav-choose-which {
  background: #fff;
  width:30%;
  margin-left:3%;
  display: inline;
  margin: auto;
  box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
  padding: 5%;
}
.nav-web903{
  display: none;
}
}
/* moblie view */
@media screen and (max-width: 1023px) {
  .nav-logo{
    width:100%;
    height:80%;
    margin-top: 30%;
  }
  .mobile903{
  margin-left: 10%;
  /* position: relative;
  bottom: 30px; */
  }
  .nav-left{
    display: none;
  }
  .app-left-mobile903{

    /* float: right; */
    /* width: 18%; */
    /* background-color: red; */
  }
  .nav-mobile903{
    display: none;
  }
.navbar-item-img-cos img{
  position: relative;
  /* left: 250%; */
}
.navbar-item-costom{
  display: block
}
.nav-list-link{
background: #fff;
background-color:#fff;
border-radius: 4px 4px 0 0;
color: #363636;
border-bottom: solid 2px #3298dc;
display: flex;
font-weight: 700;
justify-content: space-between;
line-height: 1.25;
padding: .70em 1em;
margin-top: 1.5%;
margin-bottom: 1.5%;
width: 90%;
margin: auto;
margin-top: 5%;
}
.nav-list-link:hover{
background: none;
align-items: center;
border-bottom: solid 1.5px bisque;
/* background-color: #4a4a4a; */

color: #fff;
}
.nav-web-display a{
background: #fff;
background-color:#fff;
border-radius: 4px 4px 0 0;
color: #363636;

display: flex;
font-weight: 700;
justify-content: space-between;
line-height: 1.25;
padding: .70em 1em;
margin-top: 1.5%;
margin-bottom: 1.5%;
}
.nav-web-display a:hover{
background: none;
align-items: center;
border-bottom: solid 1.5px bisque;
/* background-color: #4a4a4a; */

color: #fff;
}
.nav-dropdown li {
margin-top: 5px;
padding-left: 5%;
color: black;
}
.navbar-end-costom{
float: right;
height: 1px;
text-align: right;
/* background-color: red; */
width: 43%;
position: relative;
bottom: 17px;
left: 4%;
}
.navbar-end-costom i{
font-size: 180%;
color: #fff;
}
.nav-link-contom{
font-size: 130%;
color: #fff;
}
.nav-pay-quiz-moblie{
/* outline: 0; */
font-size: 1rem;
font-weight: bold;
  /* font-weight: 370; */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  background-color: #2ba27c;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.20em 0.60em;
  padding-top: 3px;
  min-width: 15ch;
  /* margin-left: 1%; */
  margin-right: 5%;
  min-height: 38px;
  text-align: center;
  /* line-height: 1.1; */
  transition: 220ms all ease-in-out;
  position: relative;
  bottom: 5px;
  left: 21%;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  font-size: 12px;
  font-weight: bold;
  background: #292A3A;
}

}