
.assignmentClass-body{
    padding-top: 3%;
}

.assignmentClass-left-hander{

}

.assignmentClass-summary {
    margin-left: 4%;
    position: relative;
    bottom: 20px;
    /* background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(242, 242, 242) 100%); */
   }

.assignmentClass-player{
    width: 74%;
    margin-left: 5%;
    margin-top: 1%;
}
.assignmentClass-player h2{
    -webkit-box-align: left;
    align-items: left;
    color: #fff;
    text-align: left;
    font-size: 17px;
    font-weight: 550;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-bottom: 1%;
}
.assignmentClass-left-hander h2{
    margin-left: 10%;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.2px;
}
.assignmentClass-left-hander h4{
    margin-top: 2%;
    margin-left: 10%;
    /* display: inline; */
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}
.assignmentClass-left-hander h6{
    margin-left: 10%;
    margin-top: 1%;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}





.assignmentClass-questions h2{
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    position: relative;
    bottom: 17px;
}
.assPublicAss{
    width: 30%;
    margin: auto;
    /* background-color: red; */
    position: relative;
    right: 15%;
    
    /* text-align: left !important; */
}
.assPublicAss p{
    color: #fff;
}

.assinmentPu-image{
    width: 59%;
    margin: auto;
    text-align: center;
    margin-top: 0.5%;
}
.assinmentPu-image img{
    height:80;
    width:50%
}
.assinmentPu-modal-head h3{
    position: relative;
    bottom: 14%;
    text-align: center;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.30rem;
    display: block;
    text-align: center;
    color: #000;
    line-height: 1.25rem;
    font-weight: bold;
    line-height: 1.25;

}



/* web view */
@media (min-width: 1024px)  {
    .assignmentClass-tab{
        margin-top: 8%;
        width: 60%;
        margin-left: 10%;
        
      }
      .assignmentClass-tab ul{
        border-bottom-width: 0.5px;
        
      }
    .assignmentClass-tab li {
        margin-right: 10%;
    
    }
    .assignmentClass-tab ul li a {
     color: #fff !important
  
  }
    .assignmentClass-tab li a {
        /* border-bottom-color: #3273dc; */
        display: inline-block;
        vertical-align: top;
        min-width: 12px;
        height: 42px;
        line-height: 42px;
        width: auto;
        font-size: 1rem;
        text-decoration: none;
        outline: 0px;
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        box-shadow: none;
        background: transparent;
        max-width: 20rem;
        border-image: initial;
        color: #fff;
        letter-spacing: 0.2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0px 0.5rem 2rem;
        margin-right: 1rem;
        border-top: none;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
       
    }
    .assignmentClass-tab  li.is-active a {
     
        display: inline-block;
        vertical-align: top;
        min-width: 12px;
        height: 42px;
        line-height: 42px;
        width: auto;
        font-size: 1rem;
        text-decoration: none;
        outline: 0px;
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        box-shadow: none;
        background: transparent;
        max-width: 20rem;
        border-image: initial;
        color: #fff;
        border-bottom: 3px inset #fff;
        letter-spacing: 0.2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0px 0.5rem 2rem;
        margin-right: 1rem;
        border-top: none;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
    }
    .assignment-left-body{
        display: block;
    }
    .assignmentClass-questions{
        width: 74%;
        margin-left: 5%;
        position: relative;
        /* bottom: 10px; */
        /* margin-top: 2%; */
    }
    .assignment-questions{
        width: 74%;
        margin-left: 5%;
        /* margin-top: 1%; */
    }
    .assignment-book-loading{
        width: 20%;
        /* display: flex; */
        box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
        border-radius: 5px;
        position: relative;
        /* right: 5%; */
        padding:1.5%;
        padding-top: 1%;
        /* padding-top:6%; */
        background: #fff;
        height: 18%;
        margin-bottom: 1.2%;
        margin-left: 1.5%;
        margin-right: 1.5%;
    }
    .assignment-book-loading{
        /* margin-top: 2%; */
        -webkit-box-align: center;
        align-items: center;
        color: rgb(51, 51, 51);
        /* text-align: center; */
        font-size: 11px;
        font-weight: 600;
        line-height: 1.25;
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        word-break: break-word;
        overflow-wrap: break-word;
        margin-bottom: 2%;  
        text-align: center;
    }
    .assignment-book{
        width: 20%;
        /* display: flex; */
        box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
        border-radius: 5px;
        position: relative;
        /* right: 5%; */
        padding:1.5%;
        padding-top: 1%;
        /* padding-top:6%; */
        background: #fff;
        height: 190px;
        margin-bottom: 1.2%;
        margin-left: 1.5%;
        margin-right: 1.5%;
        }

        .assignment-book h3{
            /* margin-top: 2%; */
            -webkit-box-align: center;
            align-items: center;
            color: rgb(51, 51, 51);
            /* text-align: center; */
            font-size: 11px;
            font-weight: 600;
            line-height: 1.25;
            font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
            word-break: break-word;
            overflow-wrap: break-word;
            margin-bottom: 2%;  
            text-align: center;
        }
        .assignment-left-body{
            margin-top: 5%;
            width: 93%;
            box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
            border-radius: 5px;
            position: relative;
            right: 5%;
            padding:4%;
            padding-top:6%;
            background: #fff;
            height: auto;
            margin-bottom: 5% ;
        }
        .assignment-left-body  h4{
           margin-top: 1%;
           margin-bottom: 2%;
           -webkit-box-align: center;
           align-items: center;
           color: rgb(51, 51, 51);
           font-size: 17px;
           font-weight: 600;
           line-height: 1.25;
           font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
           word-break: break-word;
           overflow-wrap: break-word;
        }
        .assignment-left-body button{
            margin-top: 2%;
            margin-bottom: 3%;
        }
        .assignment-mobile-body{
            display: none;
        }
}


/* moblie view */
@media screen and (max-width: 1023px) {
    .assignment-left-body{
        display: none;
    }
    .assignmentClass-tab{
     
        width: 97%;
        /* margin-left: 10%; */
        margin: auto;
        margin-top: 7%;
      }
      .assignmentClass-tab ul{
        border-bottom-width: 0.5px;
        
      }
    .assignmentClass-tab li {
        margin-right: 1%;
    
    }
    .assignmentClass-tab ul li a {
     color: #fff !important
  
  }
    .assignmentClass-tab li a {
        /* border-bottom-color: #3273dc; */
        display: inline-block;
        vertical-align: top;
        min-width: 12px;
        height: 42px;
        line-height: 42px;
        width: auto;
        font-size: 1rem;
        text-decoration: none;
        outline: 0px;
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        box-shadow: none;
        background: transparent;
        max-width: 20rem;
        border-image: initial;
        color: #fff;
        letter-spacing: 0.2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0px 0.5rem 2rem;
        margin-right: 1rem;
        border-top: none;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
       
    }
    .assignmentClass-tab  li.is-active a {
     
        display: inline-block;
        vertical-align: top;
        min-width: 12px;
        height: 42px;
        line-height: 42px;
        width: auto;
        font-size: 1rem;
        text-decoration: none;
        outline: 0px;
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        box-shadow: none;
        background: transparent;
        max-width: 20rem;
        border-image: initial;
        color: #fff;
        border-bottom: 3px inset #fff;
        letter-spacing: 0.2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0px 0.5rem 2rem;
        margin-right: 1rem;
        border-top: none;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
    }
    .assignmentClass-questions{
        width: 99%;
        margin: auto;
        /* margin-left: 5%; */
        position: relative;

        /* bottom: 10px; */
        /* margin-top: 2%; */
    }
    .assignment-questions{
        width: 95%;
        margin: auto;
        /* margin-left: 5%; */
        /* margin-top: 1%; */
    }
    .assignment-book{
        width: 47%;
        /* display: flex; */
        box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
        border-radius: 5px;
        position: relative;
        /* right: 5%; */
        padding:1.5%;
        padding-top: 1%;
        /* padding-top:6%; */
        background: #fff;
        height: 170px;
        margin-bottom: 1.2%;
        margin-left: 1.5%;
        margin-right: 1.5%;
        }
        .assignment-book h3{
            /* margin-top: 2%; */
            -webkit-box-align: center;
            align-items: center;
            color: rgb(51, 51, 51);
            /* text-align: center; */
            font-size: 11px;
            font-weight: 600;
            line-height: 1.25;
            font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
            word-break: break-word;
            overflow-wrap: break-word;
            margin-bottom: 2%;  
        }

        .assignment-mobile-body{
            margin-top: 5%;
            width: 97%;
            box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
            border-radius: 5px;
            position: relative;
            /* right: 5%; */
            padding:4%;
            padding-top:6%;
            background: #fff;
            height: auto;
            margin: auto;
            /* margin-bottom: 5% ; */
        }
        .assignment-mobile-body  h4{
           margin-top: 1%;
           margin-bottom: 2%;
           -webkit-box-align: center;
           align-items: center;
           color: rgb(51, 51, 51);
           font-size: 17px;
           font-weight: 600;
           line-height: 1.25;
           font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
           word-break: break-word;
           overflow-wrap: break-word;
        }
        .assignment-mobile-body button{
            margin-top: 2%;
            margin-bottom: 3%;
        }
}