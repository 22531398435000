.assignment-left-hander{
    padding-top: 4%;
}
.assignment-loading{
    margin-top: 10%;
    text-align: center;
}
.assignment-left-hander h2{
    margin-left: 10%;
    color: rgb(51, 51, 51);
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.2px;
}
.assignment-left-hander h4{
    margin-top: 2%;
    margin-left: 10%;
    /* display: inline; */
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 32px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}
.assignment-left-hander h6{
    margin-left: 10%;
    margin-top: 1%;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 17px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}

.assignment-left-time{
    padding-bottom: 3%;
    padding-top: 2%;
    border-bottom:1px solid #dee2e6;
    margin-bottom: 4%;
}
.assignment-tab{
  margin-top: 8%;
  width: 60%;
  margin-left: 10%;
  
}
.assignment-tab ul{
    border-bottom-width: 0.5px;
    
  }
.assignment-tab li {
    margin-right: 8%;

}
.assignment-tab li a {
    /* border-bottom-color: #3273dc; */
    display: inline-block;
    vertical-align: top;
    min-width: 12px;
    height: 42px;
    line-height: 42px;
    width: auto;
    font-size: 1rem;
    text-decoration: none;
    outline: 0px;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-shadow: none;
    background: transparent;
    max-width: 20rem;
    border-image: initial;
    letter-spacing: 0.2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px 0.5rem 2rem;
    margin-right: 1rem;
    border-top: none;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
   
}
.assignment-tab  li.is-active a {
 
    display: inline-block;
    vertical-align: top;
    min-width: 12px;
    height: 42px;
    line-height: 42px;
    width: auto;
    font-size: 1rem;
    text-decoration: none;
    outline: 0px;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-shadow: none;
    background: transparent;
    max-width: 20rem;
    border-image: initial;
    color: rgb(81, 45, 168);
    border-bottom: 3px inset rgb(81, 45, 168);
    letter-spacing: 0.2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px 0.5rem 2rem;
    margin-right: 1rem;
    border-top: none;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
}




.assignment-summary {
 margin-left: 4%;
 /* background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(242, 242, 242) 100%); */
}
.assignment-summary-left{
    margin: 2rem 0px 0px;
    background-color: rgb(255, 255, 255);
    /* display: flex; */
    /* flex: 1 1 0%; */
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
    margin-top: 3%;
    width: 95%;
}
.assignment-summary-sub{
/* position: absolute; */
height: 130px;
width: 130px;
border-radius: 100%;
border: 8px solid rgb(242, 242, 242);;
text-align: center;
padding-top: 20%;
margin: auto;
margin-left: 10%;
}
.assignment-summary-sub span{
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}
.assignment-summary-sub p{
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 15px;
    font-weight: 500;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}
.assignment-summary-head{
    margin-top: 1%;
    padding-bottom: 2%;
    border-bottom: 1px solid rgb(242, 242, 242);;
    width: 90%;
    margin-bottom: 2%;
    /* margin: auto; */
}
.assignment-summary-head h3{
    margin-left: 3%;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}
.assignment-summary-body{
    width: 98%;
    margin: auto;
}
.assignment-summary-details{
    /* margin-top: 10%; */
    border-bottom: 1px solid rgb(242, 242, 242);
    margin-bottom: 3%;
    padding-top: 2%;
    padding-bottom: 3%;
    padding-right: 5%;
}
.assignment-summary-details h2{
    /* margin-top: 2%; */
    margin-left: 3%;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}
.assignment-summary-details h3{
    /* margin-top: 0%; */
    margin-left: 3%;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}
.assignment-summary-details h5{
    float: right;
    /* margin-top: 2%; */
    margin-left: 3%;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}
.assignment-difficult{
    margin-left: 4%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 50%, rgb(242, 242, 242) 100%);
}
.assignment-right{
    width: 30%;
    float: right;
}
.assignment-right button{
 margin-left: 1%;
 margin-right: 1%;
}
.assignment-difficult-sub{
    margin: 2rem 0px 0px;
    background-color: rgb(255, 255, 255);
    /* display: flex; */
    /* flex: 1 1 0%; */
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
    padding-bottom: 10%;
    margin-top: 0%;
    /* margin-top: 1%; */
}
.assignment-difficult-head{
    border-bottom: 1px solid rgb(242, 242, 242);
    margin-bottom: 5%;
    padding-top: 2%;
    padding-bottom: 3%;
    padding-right: 5%;
    padding-left: 3%;

}
.assignment-difficult-head h2{
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}
.assignment-difficult-sub p{
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    text-align: center;
    font-size: 14px;
    font-weight: 550;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
}

.assignment-player{
    width: 74%;
    margin-left: 5%;
    margin-top: 1%;
}
.assignment-player h2{
    -webkit-box-align: left;
    align-items: left;
    color: rgb(51, 51, 51);
    text-align: left;
    font-size: 17px;
    font-weight: 550;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-bottom: 1%;
}
.assignment-table{

}
.assignment-table thead tr th{

}





.assignment-image{
    width: 93%;
}
.assignment-input{
    width: 50%;

}
.assignment-list{
  padding-left: 1%;
  padding-right: 3%;
}
.assignment-list h3{
 margin-top: 2%;
 -webkit-box-align: center;
 align-items: center;
 color: rgb(51, 51, 51);
 /* text-align: center; */
 font-size: 13px;
 font-weight: 600;
 line-height: 1.25;
 font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
 word-break: break-word;
 overflow-wrap: break-word;
 margin-bottom: 2%;
}
.assignment-card-modal{
width: 98%;
box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
border-radius: 5px;
position: relative;
/* right: 5%; */
padding:1.5%;
padding-top: 1%;
/* padding-top:6%; */
background: #fff;
height: auto;
margin-bottom: 1.2%;

/* margin-bottom: 5% ; */
}
.assignment-card-modal h5{
    /* margin-top: 2%; */
 -webkit-box-align: center;
 align-items: center;
 color: rgb(51, 51, 51);
 /* text-align: center; */
 font-size: 13px;
 font-weight: 600;
 line-height: 1.25;
 font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
 word-break: break-word;
 overflow-wrap: break-word;
}
.main-list{
    width: 99%;
    height: 400px;
    background-color: rgb(242, 242, 242);;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 1%;
    overflow-y: auto
}
.assignment-book-modal{
width: 30%;
/* display: flex; */
box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
border-radius: 5px;
position: relative;
/* right: 5%; */
padding:1.5%;
padding-top: 1%;
/* padding-top:6%; */
background: #fff;
height: 190px;
margin-bottom: 1.2%;
margin-left: 1.5%;
margin-right: 1.5%;
}
.assignment-book-modal  h4{
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    /* text-align: center; */
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-bottom: 10px;
}
.jDhsfY {
    width: 95%;
    height: 90%;
    object-fit: cover;
    object-position: center center;
    max-height: inherit;
    border-radius: 0.25rem 0.25rem 0px 0px;
}
.assigment-modal-title {
    text-align: center;
}
.assigment-modal-title h2{
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    /* text-align: center; */
    font-size: 15px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    
}
.assigment-modal-title p{
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 51, 51);
    /* text-align: center; */
    font-size: 15px;
    font-weight: 500;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-bottom: 10px;
}
.assigment-modal-bottom{
 width: 50%;   
 margin: auto;
}
.assigment-modal-bottom button{
    margin-left: 2%;
    margin-right: 2%;
}
.container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
  }
  
  .container > p {
    font-size: 1rem;
  }
  
  .container > em {
    font-size: .8rem;
  }
  
  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }
  
  .dropzone:focus {
    border-color: #2196f3;
  }
  
  .dropzone.disabled {
    opacity: 0.6;
    float: left;
  }
  .box-modal-custom h3{
    -webkit-box-align: left;
    align-items: left;
    color: rgb(51, 51, 51);
    /* text-align: center; */
    font-size: 17px;
    font-weight: 700;
    line-height: 1.25;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-bottom: 10px;
    margin-top: 2%;
  }
  .assignment-quiz-fullbody{
    height: 400px;
    overflow:auto;
  }
  .assignment-quiz-head{
    width: 96%;
   
    /* display: flex; */
    box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    position: relative;
    /* right: 5%; */
    padding:1.5%;
    padding-top: 1%;
    /* padding-top:6%; */
    background: #fff;
    /* height: 190px; */
    margin-bottom: 1.2%;
    margin: auto;
    margin-top: 1%;
  }
  .assignment-quiz-head a i{ 
      float: right;
      font-size: x-large;
      color: #f14668;;
  }
  .assignment-quiz-body{
      width: 93%;
      margin: auto;
     
  }
  .assignment-quiz-modal{
    width: 48%;
    /* display: flex; */
    box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    position: relative;
    /* right: 5%; */
    padding:1.5%;
    padding-top: 1%;
    /* padding-top:6%; */
    background: #fff;
    /* height: 190px; */
    margin-bottom: 1.2%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    }
    .assignment-quiz-modal h4{
        -webkit-box-align: center;
        align-items: center;
        color: rgb(51, 51, 51);
        /* text-align: center; */
        font-size: 1px;
        font-weight: 200;
        line-height: 1.25;
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        word-break: break-word;
        overflow-wrap: break-word;
        margin-bottom: 10px;

    }
    .assignment-quiz-modal h3{
        -webkit-box-align: center;
        align-items: center;
        color: rgb(51, 51, 51);
        /* text-align: center; */
        font-size: 10px;
        font-weight: 400;
        line-height: 1.25;
        font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
        word-break: break-word;
        overflow-wrap: break-word;
        margin-bottom: 10px;

    }
    .assignment-quiz-modal img{
        width: 10%;
        height: auto;
        float: left;
    }
   
    .assignment-length{
        width: 30%;
        margin: auto;
        /* background-color: red; */
        position: relative;
        right: 15%;
        
        /* text-align: left !important; */
    }
    .assignment-length p{
        color: #000;
    }