
.assignments-sub{
    margin: auto;
    width: 80%;
    margin-top: 3%;
}
.assignments-add{
    width: 20%;
    background-color: red;
    /* height: 10px; */
}
.assignments-add button{
    float: left;
    position: relative;
    top:5px;
    left: 5%;
}
.assignments-input{
    width:30%;
    margin-left: 70%;
    margin-top:8%;
    margin-bottom: 1%;
    /* float: right; */
}
.assignments-table{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    position: relative;
}

.hoKSwH {
    border-radius: 0.2rem 0.2rem 0px 0px;
    border: 1px solid rgb(204, 204, 204);
    flex-direction: row;
    align-items: flex-end;
    padding-right: 0.3rem;
    box-shadow: none;
}
.assignments-tableAssig{
    width:15%;
    display: inline-block;
    text-decoration: none;
    outline: 0px;
    box-shadow: none;
    background: transparent;
    max-width: 20rem;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    
    letter-spacing: 0.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 10px;
    height: 100%;
    min-height: 48px;
    line-height: 1.25;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    padding-top: 1.3%;
    margin-left: 1%;
}
.assignments-tableAssig p{
    min-width: 12px;
    font-size: 1.2rem;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 1.25;
}
.assignments-tableTitle{
    width:9%;
    display: inline-block;
    text-decoration: none;
    outline: 0px;
    box-shadow: none;
    background: transparent;
    max-width: 20rem;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;

    letter-spacing: 0.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 10px;
    height: 100%;
    min-height: 48px;
    line-height: 1.25;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    padding-top: 1.3%;
    margin-left: 0.5%;
}
.active-data{
    color: rgb(70, 23, 143);
    border-bottom: 1.7px solid rgb(70, 23, 143);
}
.assignments-tableTitle p{
    min-width: 12px;
    font-size: 1.2rem;
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 1.25;
}